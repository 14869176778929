import {useAppDispatch, useAppSelector} from "hooks/useRedux";
import {setShowModal} from "state/features/magicSlice";

const MagicFrame = () => {
  const {attributes} = useAppSelector((state) => state.magic);
  const dispatch = useAppDispatch();

  const baseUrl = "https://invitations.lendsqr.com";
  const url = `${baseUrl}/init?key=${attributes.key}&product_id=${attributes.product_id}`;
  return (
    <div className="w-full h-full justify-center items-center flex">
      <div className="magicModal">
        <img
          src="https://lsq-email-assets.s3.us-east-2.amazonaws.com/cancel.svg"
          className="cancel-logo"
          onClick={() => dispatch(setShowModal(false))}
          alt="cancel"
        />
        <iframe
          id="lsq-widget-iframe"
          allow={`midi; geolocation ${baseUrl}; camera ${baseUrl}`}
          src={url}
          style={{
            // position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 0,
          }}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  );
};

export default MagicFrame;

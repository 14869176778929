// components/Accordion.js
import Image from "next/image";
import {useEffect, useRef, useState} from "react";

interface DynamicAccordionProps {
  title: string
  children: React.ReactNode
  isOpen: boolean
  onToggle: () => void
  extras?: string
}

const DynamicAccordion = ({
  title,
  children,
  onToggle,
  isOpen,
  extras,
}: DynamicAccordionProps) => {
  const [height, setHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    onToggle();
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [isOpen]);

  return (
    <div className={`border border-gray-800 rounded-[6px] mb-4 ${extras}`}>
      <div
        className="flex justify-between items-center p-4 cursor-pointer bg-white-100 rounded-[6px]"
        onClick={toggleAccordion}
      >
        <h3 className="body-lg-bold inter-display text-primary">{title}</h3>
        <div
          className={`transition-transform duration-300" ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <Image
            src="/assets/icons/down-icon-new.svg"
            width={12}
            height={7}
            alt="drawer icon"
          />
        </div>
      </div>
      <div
        ref={contentRef}
        style={{height: height}}
        className="overflow-hidden transition-all duration-300 ease-in-out"
      >
        <div className={`py-4 bg-white-100 border-t border-gray-800 ${extras}`}>
          <div className="px-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DynamicAccordion;
